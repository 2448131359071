@import 'lib/assets/styles/mixins';

.component {
  clear: both;
}

.container {
  padding-bottom: 30px;

  @include media-breakpoint-up(md) {
    display: flex;
    padding-bottom: 60px;

    .variant--featured & {
      display: block;
    }
  }
}

.contentWrapper {
  @include media-breakpoint-up(xl) {
    position: relative;
    padding-right: 25px;
    width: calc(100% - var(--mom--sidebar));

    .variant--featured & {
      padding-right: 0;
      width: 100%;
    }
  }
}
