@import 'lib/assets/styles/mixins';

.component {
  ul {
    @include clear-ul-li;
  }

  :global {
    .header-menu-container {
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;

      -ms-overflow-style: none; // Hide scrollbar for IE and Edge
      scrollbar-width: none; // Hide scrollbar for Firefox

      &::-webkit-scrollbar {
        display: none; // Hide scrollbar for Chrome, Safari and Opera
      }

      .header-menu {
        display: flex;
        flex-wrap: nowrap;

        &::before,
        &::after {
          content: '';
          flex: 1;
        }
      }

      a {
        white-space: nowrap;
        padding: 10px 16px;
        display: block;
        text-decoration: none;
        @apply hover:text-navy dark:hover:text-silver;
      }

      .menu-item {
        /*&:not(:last-child) {
          a {
            border-right: 1px solid #888;
          }
        }*/

        /*&.menu-item-has-children {
          & > a {
            &::after {
              content: '';
              display: inline-block;
              // background-image: url(../../images/drop-w.svg?1);
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              height: 10px;
              width: 12px;
              margin-left: 10px;
            }
          }

          &:hover > a {
            color: var(--mom--color--navy);
          }
        }*/
      }

      .sub-menu {
        display: none;
        /*position: absolute;
        top: 100%;
        left: 0;
        background-color: #000;
        min-width: 160px;
        text-align: left;
        z-index: 1000;

        .menu-item {
          display: block !important;
          padding: 0;

          a {
            padding: 10px 20px !important;
            border-right: 0;
          }
        }*/
      }
    }
  }
}
