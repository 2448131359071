@import 'lib/assets/styles/mixins';

.component {
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;

  @include media-breakpoint-up(md) {
    margin-bottom: 20px;
  }
}

.inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

button.prev,
button.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(#000, 0.3);
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  text-indent: -999px;
  color: transparent;
  opacity: .7;

  .component:hover & {
    background-color: rgba(#000, 0.9);
  }
}

button.prev {
  left: 0;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzOCAzOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzggMzg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHRpdGxlPiZhbXA7Z3Q7IFNlZSBNb3JlPC90aXRsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTAsMTljLTAuMSw1LjEsMS45LDkuOSw1LjYsMTMuNEM5LjEsMzYsMTQsMzgsMTksMzhjNS4xLDAuMSw5LjktMS45LDEzLjQtNS42QzM2LDI4LjksMzgsMjQsMzgsMTkKCWMwLjEtNS4xLTEuOS05LjktNS42LTEzLjRDMjguOSwxLjksMjQuMS0wLjEsMTksMEMxMy45LTAuMSw5LjEsMS45LDUuNiw1LjZDMS45LDkuMS0wLjEsMTMuOSwwLDE5eiBNNy42LDMwLjQKCWMtMy4xLTMtNC44LTcuMS00LjctMTEuNGMtMC4xLTQuMywxLjYtOC40LDQuNy0xMS40YzMtMy4xLDcuMS00LjgsMTEuNC00LjdjNC4zLTAuMSw4LjQsMS42LDExLjQsNC43YzMuMSwzLDQuOCw3LjEsNC43LDExLjQKCWMwLjEsNC4zLTEuNiw4LjQtNC43LDExLjRjLTMsMy4xLTcuMSw0LjgtMTEuNCw0LjdDMTQuNywzNS4yLDEwLjYsMzMuNSw3LjYsMzAuNEw3LjYsMzAuNHogTTcuMiwxOWwxMS40LDExLjNsMi0ybC04LThoMTguMnYtMi44CglIMTIuNWw3LjktNy45bC0yLTJMNy4yLDE5eiIvPgo8L3N2Zz4K);
  border-radius: 0 10px 10px 0;
}

button.next {
  right: 0;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzOCAzOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzggMzg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KPC9zdHlsZT4KPHRpdGxlPiZhbXA7Z3Q7IFNlZSBNb3JlPC90aXRsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTMyLjQsNS42QzI4LjksMS45LDI0LjEtMC4xLDE5LDBDMTMuOS0wLjEsOS4xLDEuOSw1LjYsNS42QzEuOSw5LjEtMC4xLDEzLjksMCwxOWMwLDUsMiw5LjksNS42LDEzLjQKCWMzLjUsMy43LDguMyw1LjcsMTMuNCw1LjZjNSwwLDkuOS0yLDEzLjQtNS42YzMuNy0zLjUsNS43LTguMyw1LjYtMTMuNEMzOC4xLDEzLjksMzYuMSw5LjEsMzIuNCw1LjZ6IE0zMC40LDMwLjQKCWMtMywzLjEtNy4xLDQuOC0xMS40LDQuN2MtNC4zLDAuMS04LjQtMS42LTExLjQtNC43Yy0zLjEtMy00LjgtNy4xLTQuNy0xMS40Yy0wLjEtNC4zLDEuNi04LjQsNC43LTExLjRjMy0zLjEsNy4xLTQuOCwxMS40LTQuNwoJYzQuMy0wLjEsOC40LDEuNiwxMS40LDQuN2MzLjEsMyw0LjgsNy4xLDQuNywxMS40QzM1LjIsMjMuMywzMy41LDI3LjQsMzAuNCwzMC40TDMwLjQsMzAuNHogTTE5LjYsNy42bC0yLDJsNy45LDcuOUg3LjJ2Mi44aDE4LjIKCWwtOCw4bDIsMkwzMC44LDE5TDE5LjYsNy42eiIvPgo8L3N2Zz4K);
  border-radius: 10px 0 0 10px;
}

.image {
  @include ratio(4, 3);
  position: relative;
  flex: 0 0 100%;

  img {
    object-fit: cover;
  }
}

.pagination {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 6px 12px;
  background-color: rgba(#000, 0.3);
  color: var(--mom--color--white);
  font-size: 12px;
  opacity: .7;

  .component:hover & {
    background-color: rgba(#000, 0.9);
  }
}
