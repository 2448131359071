@layer base {
  @font-face {
    font-family: 'Bradford';
    src: url('../fonts/bradford/BradfordLLWeb-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Bradford';
    src: url('../fonts/bradford/BradfordLLWeb-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'GT America';
    src: url('../fonts/gt-america/GT-America-Standard-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'GT America';
    src: url('../fonts/gt-america/GT-America-Standard-Regular-Italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'GT America';
    src: url('../fonts/gt-america/GT-America-Standard-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'GT America';
    src: url('../fonts/gt-america/GT-America-Standard-Bold-Italic.woff2') format('woff2');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }
}
