@import "mixins";

// Keyframes

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*@keyframes placeHolderShimmer{
  0%{
    background-position: -400px 0
  }
  100%{
    background-position: 400px 0
  }
}*/

// Utility classes

.clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

.d-none {
  display: none !important;
}

.d-md-none {
  @include media-breakpoint-up(md) {
    display: none !important;
  }
}

.d-md-block {
  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

.mom-img {
  $self: &;

  &__wrapper {
    position: relative;
  }

  &__share {
    @include st-transition;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}

.mom-container {
  @apply container xl:px-container mx-4 max-md:w-auto md:mx-auto md:px-4;
}

@layer components {
  .mom-pill {
    @apply font-bold text-xxs border-2 border-navy text-navy dark:border-silver dark:text-silver
    inline-block rounded-full no-underline py-1 px-2 lg:px-4 uppercase;

    &:is(a) {
      @apply hover:bg-navy hover:text-white dark:hover:bg-silver transition-all;
    }
  }

  .mom-btn {
    @apply text-base font-bold border-2 border-navy text-navy dark:border-silver dark:text-silver transition-all;
    @apply inline-block rounded-full no-underline py-0 h-9 leading-8 md:h-11 md:leading-10 px-4 lg:px-6;
    @apply hover:mom-active-bg cursor-pointer;

    svg {
      @apply inline-block size-4 mr-1 align-[-3px];
    }

    &--sm {
      @apply font-bold text-sm py-0.5 px-2 lg:px-4 h-7;
    }

    &--fill {
      @apply mom-active-bg;
      @apply hover:mom-active-border;
    }

    &--icon {
      @apply h-auto rounded-none px-0 border-0 overflow-hidden inline-block;
      @apply hover:bg-[transparent] dark:hover:bg-[transparent] hover:text-navy dark:hover:text-silver;

      svg {
        @apply block size-6;
      }
    }
  }

  .mom-active-bg {
    @apply bg-navy dark:bg-silver text-white;
  }

  .mom-active-border {
    @apply border-navy bg-[transparent] text-navy dark:border-silver dark:text-silver;
  }

  .mom-heading {
    @apply italic font-serif font-normal text-h4 md:text-h1;
  }

  .mom-section-heading {
    @apply mom-heading mb-0;
  }

  .mom-term {
    @apply font-bold text-xs uppercase text-navy dark:text-silver no-underline;

    &:is(a) {
      @apply hover:underline
    }
  }

  .mom-section {
    @apply mom-border border-t-2 mom-gap;
  }

  .mom-subheading {
    @apply font-sans text-base text-navy dark:text-silver max-md:mb-2.5;
  }

  .mom-share-bar {
    @apply absolute bottom-0 right-2 z-[9] opacity-0 group-hover:opacity-100 transition-opacity;
  }
}

@layer utilities {
  .mom-gap {
    @apply py-8 md:py-12;
  }

  .mom-gradient-block {
    @apply absolute right-full top-0 bottom-0 w-12 pointer-events-none;
    @apply bg-gradient-to-l from-white dark:from-black to-[transparent]
  }

  .mom-backdrop {
    @apply bg-white/[0.91] dark:bg-black/[0.91] backdrop-blur-md focus:outline-none;
  }

  .mom-has-video {
    @apply relative;

    &:after {
      @apply content-[''] block absolute left-0 top-0 w-full h-full;
      @apply bg-[rgba(0,0,0,0)] group-hover/PostCard:bg-[rgba(0,0,0,0.3)] transition bg-[url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCAzOCA0MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zNS43Mjk4IDIzLjk5MjhDMzguMDQ0IDIyLjY1OCAzOC4wNDQgMTkuMzE4MSAzNS43Mjk4IDE3Ljk4MzNMNS42NzA2MyAwLjY0NTI2MkMzLjM1ODEyIC0wLjY4ODU4NiAwLjQ2ODc1IDAuOTgwNDEzIDAuNDY4NzUgMy42NTAwMVYzOC4zMjYxQzAuNDY4NzQ4IDQwLjk5NTcgMy4zNTgxMiA0Mi42NjQ3IDUuNjcwNjMgNDEuMzMwOUwzNS43Mjk4IDIzLjk5MjhaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K)];
      @apply bg-no-repeat bg-[length:30px_30px];
      @apply bg-center;
    }
  }

  .mom-border {
    @apply border-dotted border-gray dark:border-gray-500;
  }

  .mom-scrollbar-hide {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      @apply hidden;
    }
  }

  .mom-scrollbar-default {
    scrollbar-width: auto;
    -ms-overflow-style: auto;

    &::-webkit-scrollbar {
      display: initial;
    }
  }

  .mom-intrinsic {
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
  }

  .mom-scrollbar-custom {
    .simplebar-scrollbar:before {
      @apply bg-navy dark:bg-silver;
    }
    
    .simplebar-wrapper {
      @apply pb-10 cursor-pointer;
    }

    .simplebar-track.simplebar-horizontal {
      @apply left-3 right-3 xl:right-container xl:left-container bg-gray-200 dark:bg-[#222] rounded-full h-2 lg:h-4;
      
      .simplebar-scrollbar {
        @apply cursor-grab;
      }
    }
  }
}
