@use "sass:math";

@import "variables";

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@function strip-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

@function rem($px-value) {
  $root-value: strip-unit($font-size-root);
  $value: strip-unit($px-value);
  $rem-value: math.div($value, $root-value) + rem;
  @return $rem-value;
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  @include media-breakpoint-up($lower, $breakpoints) {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  @include media-breakpoint-between($name, $name, $breakpoints) {
    @content;
  }
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot otf ttf woff) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin background-image($bigPath, $smallPath) {
  @include media-breakpoint-up(md) {
    background-image: url($bigPath);
  }
  @include media-breakpoint-down(md) {
    background-image: url($smallPath);
  }
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}

@mixin gradient($color1, $color2) {
  background: #{$color1}; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(#{$color1}, #{$color2}); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#{$color1}, #{$color2}); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(#{$color1}, #{$color2}); /* For Firefox 3.6 to 15 */
  background: linear-gradient(#{$color1}, #{$color2}); /* Standard syntax */
}

@mixin clear-ul-li() {
  padding-left: 0;
  margin: 0;
  li {
    list-style-type: none;
  }
}

@mixin clear-outline() {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

@mixin clear-absolute() {
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  position: relative;
}

@mixin absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin st-transition {
  transition: all .25s ease-in-out 0s;
}

@mixin st-shadow {
  box-shadow: 0 3px 6px rgba(#000, 0.16);
}

@mixin st-list {
  ul {
    padding-left: 1rem;
    list-style: none;

    li::before {
      content: "•";
      color: var(--e-global-color-secondary);
      display: inline-block;
      width: 0.8em;
      margin-left: -0.8em;
      font-size: 20px;
      line-height: 1;
      vertical-align: -2px;
    }
  }
}

@mixin st-hover() {
  &:hover {
    color: var(--mom--color--navy);

    svg path {
      stroke: currentColor;
    }
  }
}

@mixin st-blur-bg() {
  background-color: rgba(#000, 0.91);
  backdrop-filter: blur(10px);
}

@mixin clear-button() {
  border: none;
  background: transparent;
  cursor: pointer;
  box-shadow: none;
  outline: 0;
}

@mixin btn-standard() {
  --mom-btn-height: 36px;
  --mom-btn-height-offset: 0px;
  font-weight: 700;
  height: var(--mom-btn-height);
  line-height: calc(var(--mom-btn-height) - var(--mom-btn-height-offset));
  display: inline-block;
  padding: 0 15px;
  text-align: center;
  border-radius: 999px;

  @include media-breakpoint-up(md) {
    padding: 0 40px;
  }

  &:active {
    background: var(--mom--color--black);
    transform: scale(0.97);
  }
}

@mixin btn-primary() {
  @include clear-button;
  @include btn-standard;
  color: var(--mom--color--white);
  background: var(--mom--color--black);

  &:hover {
    color: var(--mom--color--white);
    background: var(--mom--color--navy);
  }

  &:active {
    background: var(--mom--color--black);
  }
}

@mixin btn-accent() {
  @include clear-button;
  @include btn-standard;
  color: var(--mom--color--white);
  background-color: var(--mom--color--navy);

  &:hover {
    color: var(--mom--color--white);
    background: var(--mom--color--silver);
  }

  &:active {
    background: var(--mom--color--navy);
  }
}

@mixin btn-secondary() {
  @include clear-button;
  @include btn-standard;
  color: var(--mom--color--black);
  background: var(--mom--color--orange);

  &:hover {
    color: var(--mom--color--black);
    background: var(--mom--color--offorange);
  }

  &:active {
    background: var(--mom--color--orange);
  }
}

@mixin btn-rounded() {
  transition: padding .25s ease-in-out 0s, height .25s ease-in-out 0s;
  text-transform: uppercase;
  font-size: 12px;
  padding: 0 10px;
  height: 24px;
  border-radius: 20px;
  line-height: 1;

  @include media-breakpoint-up(md) {
    font-size: 16px;
    padding: 0 15px;
    height: 33px;
    min-width: var(--mom--button--min-width);
    letter-spacing: 1px;
  }

  &.size--small {
    @include media-breakpoint-up(md) {
      height: 24px;
    }
  }
}

@mixin btn-outline() {
  @include clear-button;
  @include btn-standard;
  --mom-btn-height-offset: 4px;
  color: var(--mom--color--black);
  border: 2px solid var(--mom--color--orange);
  background-color: transparent;

  &:hover {
    color: var(--mom--color--black);
    background: var(--mom--color--orange);
  }

  &:active {
    border-color: var(--mom--color--offorange);
    background: var(--mom--color--offorange);
  }
}

@mixin btn-with-icon() {
  svg {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    vertical-align: -3px;
  }
}

@mixin hover-focus-active {
  &:focus,
  &:active,
  &:hover {
    @content
  }
}

@mixin link-variant($color) {
  color: $color;
  @include st-transition();
  @include hover-focus-active() {
    color: lighten($color, 5%);
  }
}

@mixin centered-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin pseudo-content() {
  content: '';
  display: block;
  position: absolute;
}

@mixin safari-only() {
  @media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) {
      @content
    }
  }
}

@mixin ie10-only() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content
  }
}

@mixin spaced() {
  letter-spacing: $letter-spacing-md;
  @include media-breakpoint-up(md) {
    letter-spacing: $letter-spacing;
  }
}

@mixin spaced-md() {
  letter-spacing: $letter-spacing-sm;
  @include media-breakpoint-up(md) {
    letter-spacing: $letter-spacing-md;
  }
}

@mixin spaced-sm() {
  letter-spacing: $letter-spacing-xs;
  @include media-breakpoint-up(md) {
    letter-spacing: $letter-spacing-sm;
  }
}

@mixin post-meta-wrapper {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 11fr 7fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0;
  }
}

@mixin post-meta-left-col {
  @include media-breakpoint-up(lg) {
    grid-area: 1 / 1 / 2 / 2;
  }
}

@mixin post-meta-right-col {
  @include media-breakpoint-up(lg) {
    grid-area: 1 / 2 / 2 / 3;
  }
}

@mixin ratio($width, $height) {
  &:before {
    @include pseudo-content;
    height: 0;
    position: relative;
    padding-bottom: math.div($height, $width)*100%;
  }
}

@mixin img-ratio($width, $height) {
  @include ratio($width, $height);

  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100% !important;
    width: auto !important;
    min-width: 100% !important;
    object-fit: cover;
  }
}

@mixin animated-placeholder {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: var(--mom--color--offgrey);
  background: linear-gradient(to right, var(--mom--color--offgrey) 10%, #c8c8c8 18%, var(--mom--color--offgrey) 33%);
  background-size: 800px 120px;
}
