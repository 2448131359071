@import 'lib/assets/styles/mixins';

.component {
  position: relative;
}

.anchor {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}
